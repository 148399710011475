import {
  laptopMaxXL,
  laptopMediumMax,
  laptopMediumMin,
  laptopMinXL,
  laptopSmallMax,
  laptopSmallMin,
  mobileLargeMax,
  mobileXLargeMax,
  mobileXXLargeMax,
  tabletMax,
} from '@propertypal/shared/src/constants/mediaQueries';
import faqHeader from '@propertypal/shared/src/resources/bg/faq-header.jpg';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { SliderButton } from '../property/SimilarProperties.style';
import { MidHeading, Text } from '../typography';

const TITLE_PADDING = 20;
const MAX_HERO_MOB_SIZE = 600;

export const CarouselContainer = styled.div`
  position: relative;

  .swiper-slide {
    height: auto;
  }
`;

export const SwiperSlideWrapper = styled.div`
  position: relative;
`;

export const SliderBtn = styled(SliderButton)`
  background-color: ${({ theme }) => theme.white};

  svg {
    color: ${({ theme }) => theme.primary};
  }

  ${({ $direction }) =>
    $direction === 'left'
      ? css`
          left: 20px;
        `
      : css`
          right: 20px;
        `}
  opacity: 0.8;
  top: 0px;
  bottom: 0px;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 1610px), (${laptopSmallMax}) {
    ${({ $direction }) =>
      $direction === 'left'
        ? css`
            left: 20px;
          `
        : css`
            right: 20px;
          `}
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }

  @media (${laptopSmallMax}) {
    display: flex;
    width: 40px;
    height: 40px;
    top: min(calc((100vw / (4 / 3) / 2)), ${MAX_HERO_MOB_SIZE / 2}px);
    bottom: auto;
    ${({ $direction }) =>
      $direction === 'left'
        ? css`
            left: 10px;
          `
        : css`
            right: 10px;
          `}
    svg {
      font-size: 20px !important;
    }
  }
`;

export const CarouselItem = styled.div`
  height: 100%;
`;

export const CarouselImg = styled.div<{
  url?: string;
  tabletUrl?: string;
  mobileUrl?: string;
  bgPositionX?: string | null;
  bgPositionY?: string | null;
  tabletBgPositionX?: string | null;
  tabletBgPositionY?: string | null;
  mobileBgPositionX?: string | null;
  mobileBgPositionY?: string | null;
}>`
  display: flex;
  align-items: center;
  height: 100%;
  background-size: cover;
  background-image: url(${({ url, tabletUrl, mobileUrl }) => url || tabletUrl || mobileUrl || faqHeader.src});
  width: 100%;
  background-repeat: no-repeat;
  padding: 120px 100px;
  background-position: ${({ bgPositionX, bgPositionY }) => `${bgPositionX || '50%'} ${bgPositionY || '50%'}`};

  @media (${laptopMediumMax}) {
    padding-left: 20px;
  }

  @media (${laptopSmallMin}) {
    min-height: 500px;
  }

  @media (${laptopSmallMax}) {
    max-height: ${MAX_HERO_MOB_SIZE}px;
    padding: 40px;
    width: 100%;
    height: auto;
    max-width: none;
    aspect-ratio: 4 / 3;
  }

  @media (${tabletMax}) {
    background-image: url(${({ url, tabletUrl, mobileUrl }) => tabletUrl || url || mobileUrl || faqHeader.src});
    ${({ tabletBgPositionX, tabletBgPositionY, bgPositionX, bgPositionY }) =>
      tabletBgPositionX || tabletBgPositionY
        ? css`
            background-position: ${`${tabletBgPositionX || bgPositionX || '50%'} ${
              tabletBgPositionY || bgPositionY || '50%'
            }`};
          `
        : ''}
  }

  @media (${mobileXLargeMax}) {
    background-image: url(${({ url, tabletUrl, mobileUrl }) => mobileUrl || tabletUrl || url || faqHeader.src});
    ${({ mobileBgPositionX, mobileBgPositionY, tabletBgPositionX, tabletBgPositionY, bgPositionX, bgPositionY }) =>
      mobileBgPositionX || mobileBgPositionY
        ? css`
            background-position: ${`${mobileBgPositionX || tabletBgPositionX || bgPositionX || '50%'} ${
              mobileBgPositionY || tabletBgPositionY || bgPositionY || '50%'
            }`};
          `
        : ''}
  }
`;

export const CarouselTitleContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  @media (${laptopSmallMax}) {
    display: none;
  }
`;

export const CarouselTitle = styled.h2<{
  fontSize?: number | null;
  fontColour?: string | null;
  maxWidth?: number | null;
  textShadow?: boolean | null;
  tabletFontSize?: number | null;
  mobileFontSize?: number | null;
}>`
  width: calc(100% - ${TITLE_PADDING}px);
  max-width: ${({ maxWidth }) => maxWidth || 680}px;
  font-size: ${({ fontSize }) => fontSize || 70}px;
  font-weight: bold;
  line-height: 1.14;
  color: ${({ theme, fontColour }) => fontColour || theme.white};
  text-shadow: ${({ textShadow }) => (textShadow === false ? 'none' : '0 0 10px rgba(0, 0, 0, 0.75)')};

  @media (min-width: 1401px) {
    width: calc(100% - ${TITLE_PADDING + 60}px);
  }

  @media (max-width: 1400px) and (${laptopMinXL}) {
    width: calc(100% - (-100vw + (100vw + ((100vw - 1280px) / 2)) + ${TITLE_PADDING + 60}px) - ${TITLE_PADDING + 60}px);
  }

  @media (${laptopMaxXL}) and (${laptopMediumMin}) {
    width: calc(100% - ${TITLE_PADDING + 90 + 60}px);
  }

  @media (${tabletMax}) {
    font-size: ${({ tabletFontSize }) => tabletFontSize || 50}px;
  }

  @media (${mobileLargeMax}) {
    font-size: ${({ mobileFontSize }) => mobileFontSize || 35}px;
  }
`;

export const TrendingContainer = styled.div`
  margin-top: -60px;
  z-index: 1;
  position: relative;
  padding: 0 15px;

  @media (${laptopSmallMax}) {
    display: none;
  }
`;

export const MobileTrendingContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100%;
  padding: 0px;

  @media (${laptopSmallMin}) {
    display: none;
  }
`;

export const TrendingWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  background: ${({ theme }) => theme.white};
  border-radius: 8px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  position: relative;

  @media (${laptopSmallMin}) {
    padding: 12px 0px;
  }

  @media (${laptopSmallMax}) {
    padding: 0px 12px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  @media (${mobileXXLargeMax}) {
    height: 245px;
  }
`;

export const TrendingPill = styled.div`
  position: absolute;
  top: -30px;
  width: auto;
  left: 20px;
  background-color: ${({ theme }) => theme.primary};
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  color: ${({ theme }) => theme.white};
  font-weight: 600;
  text-align: center;
  padding: 4px 10px;
  height: 30px;
`;

export const MobileTrendingPill = styled(TrendingPill)<{ vw?: number }>`
  top: min(calc((${({ vw }) => (vw ? `${vw}px` : '100vw')} / (4 / 3))) - 30px, ${MAX_HERO_MOB_SIZE - 30}px);
  z-index: 1;
`;

export const TrendingItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;

  @media (${laptopSmallMax}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    height: 100%;
  }
`;

export const ReadMore = styled.p`
  margin: 16px 0 0 0;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.primary};
  transition: color linear 300ms;

  &:hover {
    color: ${({ theme }) => theme.textDark};
  }

  svg {
    font-size: 13px;
  }
`;

export const ArticleText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (${laptopSmallMax}) {
    -webkit-line-clamp: 4;
  }
`;

export const TrendingItem = styled(Link)`
  position: relative;
  width: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${MidHeading} {
    transition: color linear 300ms;
  }

  &:not(:last-child) {
    &::after {
      content: '';
      position: absolute;
      background-color: ${({ theme }) => theme.backgroundLighter};
    }
  }

  &:hover {
    ${MidHeading} {
      color: ${({ theme }) => theme.primary};
    }
    ${ReadMore} {
      color: ${({ theme }) => theme.textDark};
    }
  }

  @media (${laptopSmallMin}) {
    padding: 0 20px;
    &:not(:last-child) {
      &::after {
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
      }
    }
  }

  @media (${laptopSmallMax}) {
    padding: 20px 0px;
    &:not(:last-child) {
      &::after {
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
      }
    }
  }
`;

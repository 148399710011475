import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import useWindowSize from '@propertypal/shared/src/hooks/useWindowSize';
import { ContentHubArticle } from '@propertypal/shared/src/types/strapi/overrides';
import { findRichText } from '@propertypal/shared/src/utils/content-hub';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import Chevron from '../icons/Chevron';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Box } from '../layout';
import { MidHeading } from '../typography';
import {
  ArticleText,
  CarouselContainer,
  CarouselImg,
  CarouselItem,
  CarouselTitle,
  CarouselTitleContainer,
  MobileTrendingContainer,
  MobileTrendingPill,
  ReadMore,
  SliderBtn,
  SwiperSlideWrapper,
  TrendingContainer,
  TrendingItem,
  TrendingItems,
  TrendingPill,
  TrendingWrapper,
} from './ContentHubHeaderCarousel.style';

const CONTENT_LENGTH = 200;

interface CarouselTrendingItemProps {
  article: ContentHubArticle;
  // hasTags: boolean;
  active: boolean;
}

const CarouselTrendingItem: React.FC<CarouselTrendingItemProps> = ({ article, active }) => {
  const theme = useTheme();
  return (
    <TrendingItem
      // eslint-disable-next-line max-len
      href={`/${article.attributes.subCategories?.data[0].attributes.articleCategory}/${article.attributes.subCategories?.data[0].attributes.slug}/${article.attributes.slug}`}
      prefetch={false}
    >
      <Box>
        {/* <Box h={hasTags ? '35px' : undefined}>
          {article.attributes.tag?.data?.attributes && (
            <ArticleTag
              data-testid="trending-article-tag"
              colour={article.attributes.tag.data?.attributes.textColour}
              bg={article.attributes.tag.data?.attributes.backgroundColour}
              mb={10}
            >
              {article.attributes.tag.data?.attributes.tag}
            </ArticleTag>
          )}
        </Box> */}
        <MidHeading color={active ? theme.primary : undefined} fontSize={22} fontWeight="bold" mb={15}>
          {article.attributes.title}
        </MidHeading>
        <ArticleText>
          {article.attributes.excerpt
            ? article.attributes.excerpt.substring(0, CONTENT_LENGTH)
            : findRichText(article.attributes.content, CONTENT_LENGTH)}
        </ArticleText>
      </Box>
      <ReadMore>
        <span>Read more</span>
        <Chevron size={13} />
      </ReadMore>
    </TrendingItem>
  );
};

interface ContentHubHeaderCarouselProps {
  trending: ContentHubArticle[];
}

const ContentHubHeaderCarousel: React.FC<ContentHubHeaderCarouselProps> = ({ trending }) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [activeIndex, setActiveIndex] = useState(0);

  // used for position trending tab, can't rely on 100vw in css calc, as it does not account for verical scrollbars
  const windowSize = useWindowSize(false);

  // const hasTags = useMemo(() => trending.some((article) => !!article.attributes.tag?.data?.attributes), [trending]);

  return (
    <div>
      <CarouselContainer data-testid="trending-articles">
        <Swiper
          onSwiper={setSwiper}
          simulateTouch
          slidesPerView={1}
          onTransitionEnd={(mSwiper) => {
            setActiveIndex(mSwiper.realIndex);
          }}
          loop
        >
          <SliderBtn $direction="left" onClick={() => swiper?.slidePrev()}>
            <FontAwesomeIcon icon={faChevronLeft} size={24} testID="prev-nav" />
          </SliderBtn>

          <SliderBtn $direction="right" onClick={() => swiper?.slideNext()}>
            <FontAwesomeIcon icon={faChevronRight} size={24} testID="next-nav" />
          </SliderBtn>

          <MobileTrendingPill vw={windowSize.width}>Trending</MobileTrendingPill>

          {trending.map((article) => {
            const landingPageHeader = article.attributes.landingPageHeader;
            return (
              <SwiperSlide key={article.id}>
                <SwiperSlideWrapper>
                  <CarouselItem data-testid="trending-article">
                    <CarouselImg
                      url={article.attributes.poster?.data?.attributes.url}
                      tabletUrl={article.attributes.tabletPoster?.data?.attributes.url}
                      mobileUrl={article.attributes.mobilePoster?.data?.attributes.url}
                      bgPositionX={landingPageHeader?.bgPositionX}
                      bgPositionY={landingPageHeader?.bgPositionY}
                      tabletBgPositionX={landingPageHeader?.tabletBgPositionX}
                      tabletBgPositionY={landingPageHeader?.tabletBgPositionY}
                      mobileBgPositionX={landingPageHeader?.mobileBgPositionX}
                      mobileBgPositionY={landingPageHeader?.mobileBgPositionY}
                    >
                      <CarouselTitleContainer>
                        <CarouselTitle
                          fontSize={landingPageHeader?.fontSize}
                          fontColour={landingPageHeader?.fontColour}
                          maxWidth={landingPageHeader?.maxWidth}
                          textShadow={landingPageHeader?.textShadow}
                          tabletFontSize={landingPageHeader?.tabletFontSize}
                          mobileFontSize={landingPageHeader?.mobileFontSize}
                        >
                          {article.attributes.title}
                        </CarouselTitle>
                      </CarouselTitleContainer>
                    </CarouselImg>
                  </CarouselItem>
                  <MobileTrendingContainer>
                    <TrendingWrapper>
                      <TrendingItems>
                        <CarouselTrendingItem article={article} active />
                      </TrendingItems>
                    </TrendingWrapper>
                  </MobileTrendingContainer>
                </SwiperSlideWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <TrendingContainer>
          <TrendingWrapper>
            <TrendingPill>Trending</TrendingPill>
            <TrendingItems>
              {trending.map((article, index) => (
                <CarouselTrendingItem key={article.id} article={article} active={index === activeIndex} />
              ))}
            </TrendingItems>
          </TrendingWrapper>
        </TrendingContainer>
      </CarouselContainer>
    </div>
  );
};

export default ContentHubHeaderCarousel;
